const menu = document.querySelector('.menu');
let lastScroll = 0;

window.addEventListener('scroll', () => {
  const currentScroll = window.pageYOffset;

  if (currentScroll <= 0) {
    menu.classList.remove('menu--hide');
    menu.classList.remove('menu--min');
    return;
  }

  if (currentScroll > lastScroll) {
    menu.classList.add('menu--hide');
    menu.classList.add('menu--min');
  } else if (currentScroll < lastScroll) {
    menu.classList.remove('menu--hide');
  }

  lastScroll = currentScroll;
});
